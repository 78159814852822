import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useMediaQuery
} from "@chakra-ui/react"
import { Allies, Calendar, Cart, Case, Cup, Info, FilledBook, Games, Medal, NavbarLogoOla, NewsIcon, Quests, Polls } from '@olagg/ui-kit'
import { BiGhost } from "react-icons/bi";

import HamburgerButton from "./HamburgerButton"
import LanguageMenu from "./LanguageMenu"
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { useTranslation } from 'react-i18next'

const MobileNav = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const { t } = useTranslation()

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <Flex>
            <MenuButton
              style={{ marginRight: '18px' }}
            >
              <HamburgerButton
                isOpen={isOpen}
                color="#fff"
                strokeWidth="4"
                width='20'
                height='14'
                transition={{ ease: "easeOut", duration: 0.2 }}
              />
            </MenuButton>

            {isLargerThan768 && (
              <Link to={ROUTES.HOME} state={{ fromLogo: true }}>
                <NavbarLogoOla />
              </Link>
            )}
            {!isLargerThan768 && (
              <Link to={ROUTES.HOME} state={{ fromLogo: true }}>
                <NavbarLogoOla width={'100px'} />
              </Link>
            )}
          </Flex>
          <MenuList
            bg='#0C0324'
            borderWidth='0px'
            width={'100vw'}
            paddingTop={'24px'}
            paddingLeft={'24px'}
            paddingRight={'24px'}
            paddingBottom={'50px'}
            marginTop={'12px'}
          >
            <MenuGroup
              title={t('navbar.games')}
              color={'rgba(255, 255, 255, 0.36)'}
              padding={'0px'}
              fontSize={'14px'}
            >
              <Link to={ROUTES.TORNEOS}>
                <MenuItem
                  icon={<Cup width={'16px'} height={'16px'} fillOpacity='1' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.gamesMenu.tournaments')}
                </MenuItem>
              </Link>
              <Link to={ROUTES.QUESTS}>
                <MenuItem
                  icon={<Quests width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.gamesMenu.quests')}
                </MenuItem>
              </Link>
              <Link to={ROUTES.GAMES}>
                <MenuItem
                  icon={<Games width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.gamesMenu.gameCatalog')}
                </MenuItem>
              </Link>
              {/* <Link to={ROUTES.TREASURES_GAME}>
                <MenuItem
                  icon={<BiGhost size={'16px'} color='#fff' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  <Flex>
                    {t('navbar.gamesMenu.miniGames')}
                    <Flex sx={{
                      background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                      borderRadius: '12px',
                      padding: '0 8px',
                      alignItems: 'center'
                      }} ml='3' fontSize='11px' fontWeight={700} textTransform={'uppercase'}>{t('navbar.myAccountNav.new')}
                    </Flex> 
                  </Flex>
                </MenuItem>
              </Link> */}
            </MenuGroup>
            <MenuGroup
              title={t('navbar.community')}
              color={'rgba(255, 255, 255, 0.36)'}
              padding={'0px'}
              fontSize={'14px'}
            >
              <Link to={ROUTES.RANKING}>
                <MenuItem
                  icon={<Medal width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.communityMenu.ranking')}
                </MenuItem>
              </Link>
              <Link to={ROUTES.POLLS}>
                <MenuItem
                  icon={<Polls height='19px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  <Flex flexDirection={'row'}>
                    {t('navbar.communityMenu.polls')}
                    <Flex sx={{
                      background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                      borderRadius: '12px',
                      padding: '2px 8px',
                      alignItems: 'center'
                    }} ml={'auto'} fontSize='11px' fontWeight={700} textTransform={'uppercase'}>{t('navbar.myAccountNav.new')}</Flex>
                  </Flex>
                </MenuItem>
              </Link>
              <Link to={ROUTES.OPPORTUNITIES}>
                <MenuItem
                  icon={<Case width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.communityMenu.opportunities')}
                </MenuItem>
              </Link>
              <Link to={'/landing/aprende'}>
                <MenuItem
                  icon={<FilledBook width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.communityMenu.courses')}
                </MenuItem>
              </Link>
              <Link to={ROUTES.B2B}>
                <MenuItem
                  icon={<Allies width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.communityMenu.alliesWithOla')}
                </MenuItem>
              </Link>
            </MenuGroup>
            <MenuGroup
              title={t('navbar.news')}
              color={'rgba(255, 255, 255, 0.36)'}
              padding={'0px'}
              fontSize={'14px'}
            >
              <Link to={ROUTES.NEWS}>
                <MenuItem
                  icon={<NewsIcon width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.newsMenu.alpha')}
                </MenuItem>
              </Link>
              <Link to={ROUTES.EVENTS}>
                <MenuItem
                  icon={<Calendar width='16px' height='16px' />}
                  bg='#0C0324'
                  color='#fff'
                  _active={{ fontWeight: 'bold' }}
                  fontSize='16px'
                  lineHeight={'24px'}
                >
                  {t('navbar.newsMenu.events')}
                </MenuItem>
              </Link>
            </MenuGroup>
            <Link to={ROUTES.MARKETPLACE}>
              <MenuItem
                icon={<Cart width={'18px'} height={'16px'} />}
                bg='#0C0324'
                color='#fff'
                _active={{ fontWeight: 'bold' }}
                fontSize='16px'
                lineHeight={'24px'}
                marginTop={'16px'}
              >
                {t('navbar.store')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.ABOUT}>
              <MenuItem
                icon={<Info width={'18px'} height={'18px'} />}
                bg='#0C0324'
                color='#fff'
                _active={{ fontWeight: 'bold' }}
                fontSize='16px'
                lineHeight={'24px'}
                marginBottom={'16px'}
              >
                {t('navbar.about_ola')}
              </MenuItem>
            </Link>
            <MenuGroup
              title={t('navbar.language')}
              color={'rgba(255, 255, 255, 0.36)'}
              padding={'0px'}
              fontSize={'14px'}
            >
              <LanguageMenu closeMenu={onClose} />
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default MobileNav