import { IoNotifications } from "react-icons/io5";
import { Flex, Popover, PopoverTrigger, PopoverContent, Center, Text, PopoverCloseButton } from '@chakra-ui/react'
import { useAuthStore } from "@olagg/store";
import { useTranslation } from 'react-i18next';
import NotificationsTabs from "./Tabs";
import useNotifications from "./hooks/useNotifications";
import { analytics } from "../../analytics";

export default function Notifications() {
  const { t } = useTranslation();
  const { me: { unreadNotifications } } = useAuthStore();
  const { read, unread, getAll, isLoading, markAsRead, markAsUnread } = useNotifications(); 

  const handleNotificationsIconClick = () => {
    getAll();
    triggerAnalytics();
  }

  const triggerAnalytics = () => {
    analytics()?.track("Notifications Viewed", {
      notifications_count: unreadNotifications
    })
  }
  
  return (
    <Popover placement='bottom-start'>
      <PopoverTrigger>
        <Flex position='relative' cursor='pointer' onClick={() => handleNotificationsIconClick()}>
          <IoNotifications size='20' color='white' />
          {unreadNotifications ? (
            <Center
              position='absolute' right='-2' top='-2' borderRadius='full' color='white'
              bg='linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)' fontSize='0.65rem' px='6px' 
            >
              {unreadNotifications > 99 ? '99+' : unreadNotifications}
            </Center>
          ) : null}
        </Flex>
      </PopoverTrigger>

      <PopoverContent
        mt='5' mr='5' bgColor='#3F2091' border='none' color='white' _focusVisible={{ outline: 'none' }}
        overflow='hidden' w={{ base: '100vw', sm: '400px' }}
      >
        <PopoverCloseButton p='5'></PopoverCloseButton>
        <Text p='5' fontWeight='semibold'>{t('Notifications.title')}</Text>
        <NotificationsTabs
          read={read}
          unread={unread}
          isLoading={isLoading}
          markAsRead={markAsRead}
          markAsUnread={markAsUnread}
        />
      </PopoverContent>
    </Popover>
  )
}
