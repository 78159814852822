import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import EN from "./locales/en/translation.json"
import ES from "./locales/es/translation.json"
// import PT from "./locales/pt/translation.json"
import PT from "./locales/pt/translation1.0.json"

const resources = {
  en: {
    translation: EN
  },
  es: {
    translation: ES
  },
  pt: {
    translation: PT
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;