import { fetcher, NotificationsEndpoints } from '@olagg/api-hooks';
import { Notification } from '@olagg/db-types';
import { useAuthStore } from '@olagg/store';
import { useState } from 'react';

interface IUseNotifications {
  page: number,
  limit: number
}

const useNotifications = (filter: IUseNotifications = {
  page: 1,
  limit: 50
}) => {
  const { getMe } = useAuthStore();

  const [read, setRead] = useState<Notification[]>([]);
  const [unread, setUnread] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAll = (filter?: IUseNotifications) => { getMe(); getRead(filter); getUnread(filter) };
  const getRead = (filter?: IUseNotifications) => get(filter, setRead, 'read');
  const getUnread = (filter?: IUseNotifications) => get(filter, setUnread, 'unread');
  
  const get = (_filter = filter, setFn: any, status?: 'read' | 'unread') => {
    setIsLoading(true);
    fetcher(NotificationsEndpoints.get({ ..._filter, status }))
      .then(res => {
        setFn(res.notifications);
      })
      .catch(error => {
        setFn([]);
        console.log(error)        
      })
      .finally(() => {
        setIsLoading(false);
      })
  } 

  const markAsRead = (notification: Notification) => {
    fetcher(NotificationsEndpoints.markAsRead(notification.id))
      .then(() => {
        getAll();
      })
      .catch(error => {
        console.log(error)        
      })
  } 

  const markAsUnread = (notification: Notification) => {
    fetcher(NotificationsEndpoints.markAsUnread(notification.id))
      .then(() => {
        getAll();
      })
    .catch(error => {
        console.log(error)        
      })
  } 

  const markAllAsRead = () => {
    fetcher(NotificationsEndpoints.markAllAsRead())
      .then(() => {
        getAll();
      })
      .catch(error => {
        console.log(error)        
      })
  } 

  return {
    all: {
      ...read,
      ...unread
    },
    getAll,
    read,
    getRead,
    unread,
    getUnread,
    isLoading,
    markAsRead,
    markAsUnread,
    markAllAsRead
  }
}

export default useNotifications;
