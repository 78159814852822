import { FCMSupport, requestForToken } from '@olagg/plugins'
import { Mumbai, Polygon } from "@thirdweb-dev/chains";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
// Hooks, plugins, constants, utils
import { ThirdwebProvider, en, es, metamaskWallet, walletConnect } from '@thirdweb-dev/react'
import { getMessaging, onMessage } from 'firebase/messaging'
import { useAuthStore, useOnboardingStore } from '@olagg/store'

import { AdTrackingContext } from '@/contexts/adTrackingContext'
import { Helmet } from 'react-helmet';
// Basic components
import Layout from './components/Layout';
import Loading from './pages/Loading';
import { OSName } from './utils/operationSystem'
import { ROUTES } from './routes'
import { STEPS } from './modules/Auth/constants'
import { ThirdwebProvider as ThirdwebProviderV5 } from 'thirdweb/react'
import { analytics } from './analytics'
import i18n from './i18n';
import useAuthProviders from './modules/Auth/hooks/useAuthProviders'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import useWeb3 from './hooks/useWeb3'

// Components that can be lazy loaded
const RequireAuth = lazy(() => import('./components/RequireAuth'));
const Login = lazy(() => import('@olagg/ui-kit/Login'));
const Error = lazy(() => import('./pages/Error'));
const Games = lazy(() => import('./modules/Games/Games'));
const Learn = lazy(() => import('./pages/Learn'));
const MarketPlace = lazy(() => import('./modules/Marketplace/Marketplace'));
const Profile = lazy(() => import('./modules/Profile'));
const Tokens = lazy(() => import('./modules/Tokens'));
const Referrals = lazy(() => import('./modules/Referrals'));
const Maintenance = lazy(() => import('@olagg/ui-kit/Maintenance'));
const Esports = lazy(() => import('./pages/Esports'));
const Redirect = lazy(() => import('./pages/Redirect'));
const Landing = lazy(() => import('./pages/landing'));
const LandingSecure = lazy(() => import('./pages/landingSecure'));
const LandingNR = lazy(() => import('./pages/landing-nr'));
const News = lazy(() => import('./modules/News/News'));
const NewsSingle = lazy(() => import('./modules/News/Single'));
const Opportunities = lazy(() => import('./modules/Opportunities/Opportunities'));
const OpportunitiesSingle = lazy(() => import('./modules/Opportunities/Single'));
const Events = lazy(() => import('./pages/Events'));
const B2b = lazy(() => import('./modules/B2b/B2b'));
const Ranking = lazy(() => import('./modules/Ranking/Ranking'));
const EventsSingle = lazy(() => import('./pages/Events/index'));
const QuestsList = lazy(() => import('./modules/Quests/QuestsList'));
const Tournaments = lazy(() => import('./modules/Tournaments/Tournaments'));
const Game = lazy(() => import('./modules/Games/Game'));
const Home = lazy(() => import('./modules/Home/Home'));
const About = lazy(() => import('./modules/About'));
const Posts = lazy(() => import('./modules/Posts/Posts'));
const Guides = lazy(() => import('./modules/Guides/Guides'));
const MyScholarships = lazy(() => import('./modules/Scholarships/MyScholarships'));
const Scholarship = lazy(() => import('./modules/Scholarships/Scholarship'));
const Rewards = lazy(() => import('./modules/Marketplace/Rewards'));
const PublicProfile = lazy(() => import('./modules/PublicProfile'));
const Authenticate = lazy(() => import('./modules/Auth/Authenticate'));
const SecurityProfile = lazy(() => import('./modules/Security/SecurityProfile'));
const Payments = lazy(() => import('./modules/Payments'));
const MiniGame = lazy(() => import('./modules/MiniGames/TreasuresGame/TreasuresGameList'))
const TreasuresGame = lazy(() => import('./modules/MiniGames/TreasuresGame'));
const Polls = lazy(() => import('./modules/Polls'));
const SinglePoll = lazy(() => import('./modules/Polls/Poll'));

type NotificationData = {
  title: string,
  body: string,
  image?: string,
  icon?: string,
  click_action?: string,
}

type Payload = {
  notification: NotificationData
  data: NotificationData
}

interface Window {
  prerenderReady: boolean;
}

const App = () => {
  useEffect(() => {
    const storedLang = localStorage.getItem('i18n_lang');
    if (!storedLang) {
      const browserLang = getBrowserLanguage();
      localStorage.setItem('i18n_lang', browserLang);
    }
  }, []);

  const { me, loggedIn, getMe } = useAuthStore()

  return (
    <Routes>
      <Route path={`${ROUTES.HOME}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LOGIN}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.DASHBOARD}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.B2B}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.ABOUT}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.GAME}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.GAMES}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LEARN}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.MY_PROFILE}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.REWARDS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.SCHOLARSHIPS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.SCHOLARSHIPS}/:id`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.TOKENS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.SECURITY}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.REFERRALS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.QUESTS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.QUESTS}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.MARKETPLACE}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.MARKETPLACE}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.ESPORTS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.REDIRECT}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.RANKING}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.NEWS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.NEWS}/:url`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.OPPORTUNITIES}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.OPPORTUNITIES}/:url`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.EVENTS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.EVENTS}/:eventSlug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.TORNEOS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.TORNEOS}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LANDING}/:url?/:url2?/:url3?`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LANDING_NR}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LANDING_SECURE}/:url?/:url2?/:url3?`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.POSTS}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.GUIDES}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.PUBLIC_PROFILE}/:userId`} element={<DynamicRedirect />} />
      <Route path="/:lang/*" element={<LanguageHandler loggedIn={loggedIn} me={me} />} />
      <Route path={`${ROUTES.MY_PAYMENTS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.TREASURES_GAME}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.TREASURES_GAME}/:slug`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.POLLS}`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.POLLS}/:slug`} element={<DynamicRedirect />} />
    </Routes>
  );
};

const getBrowserLanguage = () => {
  const lang = navigator.language.split('-')[0];
  return ['en', 'es', 'pt'].includes(lang) ? lang : 'en';
};

const DynamicRedirect = () => {
  const location = useLocation();
  const storedLang = localStorage.getItem('i18n_lang') || getBrowserLanguage();
  const newPath = `/${storedLang}${location.pathname}${location.search}`;
  return <Navigate to={newPath} replace />;
};

const LanguageHandler = ({ loggedIn, me }) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { getMe } = useAuthStore()
  const { lang } = useParams();
  const { i18n } = useTranslation();

  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [notification, setNotification] = useState({ title: '', body: '' });
  const toast = useToast()
  const { checkAuthStatusSupabase, initRedirectPath, checkAuthStatusNewsroom } = useAuthProviders()
  const { web3Status } = useWeb3()
  const isWeb3Enabled = web3Status()

  //Inits
  const notify = (notification: NotificationData) => toast({
    title: notification.title,
    status: 'success',
    description: notification.body,
    position: 'top-right',
    duration: 5000,
    isClosable: true
  });

  useEffect(() => {
    checkAuthStatusSupabase()
    checkAuthStatusNewsroom()
    initRedirectPath()
  }, [])


  useEffect(() => {
    if (notification?.title) {
      notify(notification)
    }
  }, [notification])


  if (FCMSupport) {
    requestForToken(me?.id);

    onMessage(getMessaging(), (payload) => {
      console.log("Message received. ", payload)
      const data = payload.data
      setNotification({ title: data?.title, body: data?.body });
    })
  }

  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId')

  if (searchParams.get('referrerId'))
    localStorage.setItem('referrerId', referrerId)

  const utm_path =
    searchParams.get('utm_path') ||
    localStorage.getItem('utm_path') ||
    ''

  if (searchParams.get('utm_path'))
    localStorage.setItem('utm_path', utm_path)

  useEffect(() => {
    document.body.style.backgroundColor = `#0C0324`
    getMe()
  }, [, pathname])

  useEffect(() => {
    const c = setTimeout(() => {
      if (me) {
        localStorage.removeItem('utm_path')

        if (utm_path) {
          navigate(utm_path)
        }
      }
    }, 5)

    return () => clearTimeout(c)
  }, [me])

  const olaChain = (import.meta.env.VITE_OLAGG_CHAIN === 'Mumbai' ? Mumbai : Polygon);
  const {
    openOnboarding,
    isOnboardingOpen,
    isRedirected,
    isSignedIn,
    setIsRedirected,
    setIsSignedIn,
    providerSignedIn,
    setForceDiscord,
    setStep,
    step
  } = useOnboardingStore()

  useEffect(() => {
    if (isOnboardingOpen == true) {
      triggerLoginViewedEvent()
    }
  }, [isOnboardingOpen])

  useEffect(() => {
    if (isRedirected == true) {
      triggerRedirectEvent()
    }
  }, [isRedirected])

  useEffect(() => {
    if (isSignedIn == true) {
      triggerSignedInEvent()
    }
  }, [isSignedIn])


  setTimeout(() => {
    (window as unknown as Window).prerenderReady = true
  }, 10000)

  const triggerRedirectEvent = () => {
    let redirectData = searchParams.get('redirect')
    analytics()?.track("User Redirected", {
      url: redirectData,
      user_agent: navigator.userAgent
    })
    setIsRedirected(false)
  }

  const checkForceDiscord = () => {
    if (step !== STEPS.none) return;
    const requireDiscordAuth = searchParams.get('require-discord-auth') || localStorage.getItem("require-discord-auth")

    if (requireDiscordAuth) {
      setForceDiscord(true)
      if (me && !me?.authProviderData?.discord?.id && step == STEPS.none) {
        setTimeout(() => {
          openOnboarding(true)
          setStep(STEPS.linkDiscord)
        }, 1000)
      } else {
        openOnboarding(false)
      }
    }
  }

  useEffect(() => {
    checkForceDiscord()
  }, [step])


  useEffect(() => {
    const redirect = searchParams.get('redirect')
    if (redirect) {
      localStorage.setItem('redirect', redirect)
    }
  }, [me])

  const checkInApp = () => {
    if (navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV') || navigator.userAgent.includes('Instagram')) {
      return true
    } else {
      return false
    }
  }

  const triggerLoginViewedEvent = () => {
    let redirectData = searchParams.get('redirect')

    let props = {
      redirect: redirectData,
      is_webview: checkInApp(),
      url: window.location.href,
      user_agent: navigator.userAgent
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp

    analytics()?.track("Login Viewed", props)
  }

  const triggerSignedInEvent = () => {

    let props = {
      identity_provider: providerSignedIn,
      user_agent: navigator.userAgent
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp

    analytics()
      ?.track('User SignedIn', props)

    analytics()
      ?.identify(`${me?.id}`, {
        email: me?.email,
        name: me?.name,
        google_id: me?.authProviderData.hasOwnProperty('google') ? me?.authProviderData.google.id : '',
        discord_id: me?.authProviderData.hasOwnProperty('discord') ? me?.authProviderData.discord.id : '',
        game_categories: me?.gameCategories,
        opportunities: me?.opportunities,
        level: me?.level?.value,
        xp: me?.level.score,
        identity_provider: providerSignedIn,
        user_agent: navigator.userAgent
      })
    setIsSignedIn(false, '')
  }

  const handleNavigationIfLoggedIn = () => {
    triggerRedirectEvent()

    const urlFull = (window.location.href).split('&')
    let queryParams = ''
    if (urlFull.length > 1) {
      urlFull.map((p, i) => {
        if (i > 0) {
          queryParams = (i == 1) ? `?${p}` : `${queryParams}&${p}`
        }
      })
    }

    let redirectData = `${searchParams.get('redirect') || localStorage.getItem("redirect")}${queryParams}`

    localStorage.removeItem("redirect")

    const requireDiscordAuth = searchParams.get('require-discord-auth')
    if (requireDiscordAuth && !redirectData.includes('require-discord-auth')) {
      redirectData = `${redirectData}?require-discord-auth=${requireDiscordAuth}`
    }
    if (redirectData.startsWith('https://')) {
      const storedLang = localStorage.getItem('i18n_lang') || getBrowserLanguage();
      navigate(`/${storedLang}/redirect`, { state: { url: redirectData } })
    } else {
      navigate(redirectData)
    }
  }

  const supportedWallets = () => {
    if (OSName() == 'macOS' || OSName() == 'Windows' || OSName() == 'Linux') {
      return [
        metamaskWallet(),
        walletConnect({
          projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
        }),
      ]
    } else {
      return [
        walletConnect({
          projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
        })
      ]
    }
  }

  const browserLanguage: string = navigator.language.slice(0, 2);
  const locale = browserLanguage === 'es' ? es() : en();

  //HANDLE LANGUAGE
  useEffect(() => {
    const storedLang = localStorage.getItem('i18n_lang');
    const browserLang = getBrowserLanguage();

    if (['en', 'es', 'pt'].includes(lang) && (lang !== storedLang && lang !== browserLang)) {
      localStorage.setItem('i18n_lang', lang);
    }

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const currentPatchname = `${location.pathname}`

  return (
    <Suspense fallback={<Loading />}>
      {import.meta.env.VITE_MAINTENANCE === 'TRUE' ? (
        <Maintenance />
      ) : (
        <ThirdwebProvider
          activeChain={olaChain}
          supportedChains={[olaChain]}
          supportedWallets={supportedWallets()}
          clientId={import.meta.env.VITE_THIRDWEB_CLIENT_ID}
          locale={locale}
        >
          <ThirdwebProviderV5>
            <Helmet>
              <link rel="canonical" href={`${import.meta.env.VITE_BASE_URL}/${i18n.language}/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="es" href={`${import.meta.env.VITE_BASE_URL}/es/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="en" href={`${import.meta.env.VITE_BASE_URL}/en/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="pt" href={`${import.meta.env.VITE_BASE_URL}/pt/${currentPatchname.split('/').slice(2).join('/')}`} />
            </Helmet>
            <Routes>
              <Route
                path={ROUTES.LOGIN}
                element={
                  !loggedIn ? (
                    <>
                      <Login
                        onClickLogin={provider => {
                          localStorage.setItem(
                            'provider',
                            provider
                          )
                        }}
                        onLoad={() => {
                          console.log('Redirecting to /login');
                        }}
                        referrerId={referrerId}
                        redirect={utm_path}
                      />
                      <Authenticate />
                    </>
                  ) : (
                    (searchParams.get('redirect') || localStorage.getItem("redirect")) ? handleNavigationIfLoggedIn() : <Navigate to={'/'} />
                  )
                }
              />
              <Route path="/" element={<Layout />}>
                <Route path={`${ROUTES.HOME}`} element={<Home />} />
                <Route path={`${ROUTES.DASHBOARD}`} element={<Navigate to='/' />} />
                <Route path={`${ROUTES.B2B}`} element={<B2b />} />
                <Route path={`${ROUTES.ABOUT}`} element={<About />} />
                <Route path={`${ROUTES.GAME}/:slug`} element={<Game />} />
                <Route path={`${ROUTES.GAMES}`} element={<Games />} />
                <Route path={`${ROUTES.LEARN}`} element={<Learn />} />
                <Route path={`${ROUTES.MY_PROFILE}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <Profile />
                  </RequireAuth>
                } />

                <Route path={`${ROUTES.REWARDS}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <Rewards />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.SCHOLARSHIPS}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <MyScholarships />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.SCHOLARSHIPS}/:id`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <Scholarship />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.TOKENS}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    {isWeb3Enabled ? (
                      <Tokens />
                    ) : (
                      <Error />
                    )}
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.SECURITY}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <SecurityProfile />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.REFERRALS}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <Referrals />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.MY_PAYMENTS}`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <Payments />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.TREASURES_GAME}`} element={<MiniGame />}/>
                <Route path={`${ROUTES.POLLS}`} element={<Polls />} />
                <Route path={`${ROUTES.POLLS}/:slug`} element={<SinglePoll />} />
                <Route path={`${ROUTES.QUESTS}`} element={<QuestsList />} />
                <Route path={`${ROUTES.QUESTS}/:slug`} element={<QuestsList />} />
                <Route path={`${ROUTES.MARKETPLACE}`} element={<MarketPlace />} />
                <Route path={`${ROUTES.MARKETPLACE}/:slug`} element={<MarketPlace />} />
                <Route path={`${ROUTES.ESPORTS}`} element={<Esports />} />
                <Route path={`${ROUTES.REDIRECT}`} element={<Redirect />} />
                <Route path={`${ROUTES.RANKING}`} element={<Ranking />} />
                <Route path={`${ROUTES.NEWS}`} element={<News />} />
                <Route path={`${ROUTES.NEWS}/:url`} element={<NewsSingle />} />
                <Route path={`${ROUTES.OPPORTUNITIES}`} element={<Opportunities />} />
                <Route path={`${ROUTES.OPPORTUNITIES}/:url`} element={
                  <RequireAuth
                    roles={['USER']}
                    me={me}
                    loggedIn={loggedIn}
                  >
                    <OpportunitiesSingle />
                  </RequireAuth>
                } />
                <Route path={`${ROUTES.EVENTS}`} element={<Events />} />
                <Route path={`${ROUTES.EVENTS}/:eventSlug`} element={<EventsSingle />} />
                <Route path={`${ROUTES.TORNEOS}`} element={<Tournaments />} />
                <Route path={`${ROUTES.TORNEOS}/:slug`} element={<Tournaments />} />
                <Route path={`${ROUTES.LANDING}/:url?/:url2?/:url3?`} element={<Landing />} />
                <Route path={`${ROUTES.LANDING_NR}/:slug`} element={<LandingNR />} />
                <Route path={`${ROUTES.LANDING_SECURE}/:url?/:url2?/:url3?`} element={<LandingSecure />} />
                <Route path={`${ROUTES.POSTS}/:slug`} element={<Posts />} />
                <Route path={`${ROUTES.GUIDES}/:slug`} element={<Guides />} />
                <Route path={`${ROUTES.PUBLIC_PROFILE}/:userId`} element={<PublicProfile />} />
                <Route path={`${ROUTES.TREASURES_GAME}/:slug`} element={<TreasuresGame />} />
              </Route>
            </Routes>
          </ThirdwebProviderV5>
        </ThirdwebProvider>
      )}
    </Suspense>
  );
};

export default App;