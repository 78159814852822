export const cdn = (
  src: string,
  options?: {
    w?: number | string,
    h?: number | string,
    q?: number,
    trim?: {left: number, top: number, width: number, height: number}
  }
) => {
  if (import.meta.env.VITE_BYPASS_CLOUDFLARE === 'true') return src;

  const quality = import.meta.env.VITE_CDN_QUALITY || 70;
  const host = `https://${import.meta.env.VITE_APP_ENV === 'production' ? '' : import.meta.env.VITE_APP_ENV === 'staging' ? 'staging.' : 'dev.'}olagg.io`;
  const dpr = (window.devicePixelRatio || 1).toFixed(1);
  const ops = `dpr=${dpr},quality=${options?.q || quality}${options?.w ? `,width=${options.w}` : ''}${options?.h ? `,height=${options.h}` : ''}`;
  const trim = options?.trim ? `,trim.left=${options.trim.left},trim.top=${options.trim.top},trim.width=${options.trim.width},trim.height=${options.trim.height}` : '';

  return `${host}/cdn-cgi/image/${ops}${trim}/${src}`;
};
