import { BackendError, Endpoint } from './endpoints/constants'
import Cookies from 'js-cookie';

/**
 * @template B The shape of the data to be sent.
 * @template R The shape of the data to be received.
 * @param _config The endpoint to be used.
 * */

export const fetcher = async <B = any | null, R = B>(
  _config: Endpoint<B>
) => {
  let config: RequestInit = {
    method: _config.method,
  }
  const token = Cookies.get('auth._token.local') || null
  const partner = Cookies.get('x-partner') || null
  const lang = localStorage.getItem('i18n_lang') || 'es'

  const defaultConfigHeaders = {
    'Content-Type': 'application/json'
  }

  if (token) {
    Object.assign(defaultConfigHeaders, {
      'Authorization': `Bearer ${token}`
    });
  }

  if (partner) {
    Object.assign(defaultConfigHeaders, {
      'x-partner': partner
    });
  }

  if (lang) {
    Object.assign(defaultConfigHeaders, {
      'x-lang': lang
    });
  }

  // console.log(lang)
  if (!_config.isForm) {
    config.headers = _config.headers || defaultConfigHeaders
    config.body = JSON.stringify(_config.body)
  } else {
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`
      }
    }
    // @ts-ignore
    config.body = _config.body
  }

  const response = await fetch(
    `${import.meta.env.VITE_API_URL || 'http://localhost:4000'}${_config.path
    }`,
    config
  )

  // response should always remove the root element. The root element is the key of the object
  // that is returned from the backend. This is done to make the endpoint more generic.
  // For example, if the endpoint is `/admin/quests` the response will be:
  // {
  //   quests: [...]
  // }

  const data = (await response.json()) as R | BackendError

  if (response.status >= 400) {
    throw data as BackendError
  }

  return data as R
}
