import { Center, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Notification } from "@olagg/db-types";
import { useTranslation } from "react-i18next";
import NotificationCard from "./Card";
import { NoNotifications, NoUnreadNotifications } from "./EmptyStates";

const NotificationsTabs = ({
  read,
  unread,
  isLoading,
  markAsRead,
  markAsUnread,
}: {
  read: Notification[],
  unread: Notification[],
  isLoading: boolean,
  markAsRead: (notification: Notification) => void,
  markAsUnread: (notification: Notification) => void,
}) => {
  const { t } = useTranslation();
  
  return (
    <Tabs>
      <TabList borderBottom='1px' borderColor='gray'  boxShadow='lg'>
        <Tab color='#C2C2C2' _active={{ bg: 'none' }} _selected={{ color: 'white', borderBottom: '2px' }}>{t('Notifications.new')}</Tab>
        <Tab color='#C2C2C2' _active={{ bg: 'none' }} _selected={{ color: 'white', borderBottom: '2px' }}>{t('Notifications.read')}</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p='0'>
          {isLoading 
            ? <Center py='20'><Spinner /></Center> 
            : <Flex
                direction='column' minH={{ base: '600px', sm: '0' }} maxH={{ base: '600px', sm: '400px'}} overflow='auto'
                css={{
                  '&::-webkit-scrollbar': {
                    width: '9px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '9px',
                    borderRadius: '24px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#1E0D50',
                    borderRadius: '24px'
                  },
                }}
              >
                {unread.length 
                  ? unread.slice(0, 5).map((notification: Notification) => (
                    <NotificationCard key={notification.id} notification={notification} markAsRead={markAsRead} markAsUnread={markAsUnread}/>
                  ))
                  : <NoUnreadNotifications />}
              </Flex>}
        </TabPanel>

        <TabPanel p='0'>
          {isLoading
              ? <Center py='20'><Spinner /></Center> 
              : (
                  <Flex
                    direction='column' minH={{ base: '600px', sm: '0' }} maxH={{ base: '600px', sm: '400px'}} overflow='auto'
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '9px',
                      },
                      '&::-webkit-scrollbar-track': {
                        width: '9px',
                        borderRadius: '24px'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#1E0D50',
                        borderRadius: '24px'
                      },
                    }}
                  >
                    {read.length
                      ? read.map((notification: Notification) => (
                          <NotificationCard key={notification.id} notification={notification} markAsRead={markAsRead} markAsUnread={markAsUnread}/>
                        ))
                      : <NoNotifications />}
                  </Flex>)}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default NotificationsTabs;
