import { Flex, Text } from '@chakra-ui/react'
import { IoNotificationsOff } from "react-icons/io5";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { useTranslation } from 'react-i18next';

export const NoNotifications = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' gap='5' alignItems='center' justifyContent='center' py='20' px='8'>
      <MdOutlineNotificationsActive size='50' color='white' />
      <Text textAlign='center'>
        <b>{t('Notifications.empty.h1')}</b>
        <br></br>
        {t('Notifications.empty.h2')}
      </Text>
    </Flex>
  )
}

export const NoUnreadNotifications = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' gap='5' alignItems='center' justifyContent='center' py='20' px='8'>
      <IoNotificationsOff size='50' color='white' />
      <Text textAlign='center'>
        <b>{t('Notifications.allRead.h1')}</b>
        <br></br>
        {t('Notifications.allRead.h2')}
      </Text>
    </Flex>
  )
}
